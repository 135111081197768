export enum KnownError {
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  USER_VERIFIED = 'USER_VERIFIED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  COMMON_PASSWORD = 'COMMON_PASSWORD',
  INVALID_MAIL = 'INVALID_MAIL',
  INVALID_REQUEST = 'INVALID_REQUEST',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  INVALID_TOKEN = 'INVALID_TOKEN',
  VERIFICATION_INVALID = 'VERIFICATION_INVALID',
  FIELD_NOT_DIRECTLY_EDITABLE = 'FIELD_NOT_DIRECTLY_EDITABLE',
  NOT_ALLOWED = 'NOT_ALLOWED',
  FILE_NOT_INCLUDED = 'FILE_NOT_INCLUDED',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  INVALID_FIELD_VALUE = 'INVALID_FIELD_VALUE',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  FORBIDDEN = 'FORBIDDEN',
  WorkspaceAlreadyExists = 'workspaceAlreadyExists',
  NoPermissions = 'noPermissions',
  RemoveMembersFirst = 'removeMembersFirst',
  NoRemoveAdmin = 'noRemoveAdmin',
  InvalidSettingName = 'InvalidSettingName',
  InvalidSettingValue = 'InvalidSettingValue',
  TwoFactorRequired = 'twoFactorRequired',
  TwoFactorAlreadyEnabled = 'twoFactorAlreadyEnabled',
  InvalidTwoFactorCode = 'InvalidTwoFactorCode',
  PropertyTypeMismatch = 'propertyTypeMismatch',
  PropertyIsMissingForeignPropertyId = 'propertyIsMissingForeignPropertyId',
  RequestingEntityInvalid = 'requestingEntityInvalid',
  INVALID_CAPTCHA = 'INVALID_CAPTCHA',
  AutomationVersionStatusInvalid = 'automationVersionStatusInvalid',
  TestException = 'testException',
}
