import {defineStore} from 'pinia'
import {useAuthApiStore} from '@toolify/client/src/stores/api/AuthApiStore/useAuthApiStore'
import {
  ILoginRequestInterfaces,
} from '@toolify/server/src/controllers/AuthController/validators/Login/types/ILoginRequestInterfaces'
import {useMeApiStore} from '@toolify/client/src/stores/api/MeApiStore/useMeApiStore'
import {setItem, getItem, removeItem} from 'localforage'
import {$ref, $$} from '@vue-macros/reactivity-transform/macros'
import {
  IUserResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IUserResponseEntity'
import {IUserUpdatedEmitPayload} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IUserUpdatedEmitPayload'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {mapUserResponseEntity} from '@toolify/client/src/modules/api/singletons/mapUserResponseEntity'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IResendTokenEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IResendTokenEmitPayload'

export const useAuthStore = defineStore('auth', () => {
  let isLoggedIn = $ref(false)
  let currentUser = $ref<IUserResponseEntity>(null)
  let isRenewingToken = $ref(false)
  const currentLinkId = $ref(null)

  const authApiStore = useAuthApiStore()
  const socketStore = useSocketStore()

  const loginWithCredentials = async (login: string, password: string,captcha?:string): Promise<Promise<ILoginRequestInterfaces['response']>> => {
    return await authApiStore.loginWithCredentials(login, password,captcha)
  }

  function initialize() {
    socketStore.socket.on(GlobalSocketEventType.UserUpdated, onUserUpdated)
    socketStore.socket.on(GlobalSocketEventType.UserUpdated, onUserUpdated)
  }

  function dispose() {
    socketStore.socket.off(GlobalSocketEventType.UserUpdated, onUserUpdated)
  }

  function onUserUpdated(payload: IToJson<IUserUpdatedEmitPayload>) {
    if(!currentUser) {
      return
    }
    if(payload.userId === currentUser.id) {
      currentUser = {
        ...currentUser,
        ...mapUserResponseEntity(payload.user),
      }
    }

  }
  const renewToken = async (): Promise<void> => {
    if(isRenewingToken) {
      return
    }
    isRenewingToken = true
    const authApiStore = useAuthApiStore()
    try {
      const response = await authApiStore.refreshToken()
      const emitPayload: IResendTokenEmitPayload = {
        token: response.token,
      }
      socketStore.socket.emit(GlobalSocketEventType.ResendToken, emitPayload)
      await setTokenWithExpiryDate(response.token, response.expiresAt)
      currentUser = await fetchCurrentUser()
      isLoggedIn = true
    } finally {
      isRenewingToken = false
    }
  }
  const fetchCurrentUser = async () => {
    const meApiStore = useMeApiStore()
    const {user} = await meApiStore.me()
    return user
  }

  async function initializeAuth() {
    const token = await getItem('auth-token')
    if(!token) {
      currentUser = null
      isLoggedIn = false
      return
    }
    if(currentUser) {
      return
    }
    await refetchCurrentUser()
  }

  const refetchCurrentUser = async () => {
    currentUser = await fetchCurrentUser()
    isLoggedIn = true
  }

  const logout = async () => {
    await removeTokenWithExpiryDate()
    currentUser = null
    isLoggedIn = false
  }

  const setTokenWithExpiryDate = async (token: string, expiresAt: Date): Promise<void> => {
    await setItem('auth-token', token)
    await setItem('auth-expires-at', String(expiresAt))
  }

  const getToken = async (): Promise<string> => {
    return await getItem('auth-token')
  }

  const removeTokenWithExpiryDate = async () => {
    await removeItem('auth-token')
    await removeItem('auth-expires-at')
  }

  return $$({
    isLoggedIn,
    currentUser,
    isRenewingToken,
    currentLinkId,
    loginWithCredentials,
    renewToken,
    fetchCurrentUser,
    initializeAuth,
    refetchCurrentUser,
    logout,
    setTokenWithExpiryDate,
    getToken,
    removeTokenWithExpiryDate,
    initialize,
    dispose,
  })
})
