<script setup lang="ts">import { computed as _computed } from 'vue';

import {defineProps} from 'vue'


const props = defineProps({
  color: {
    type: String,
    required: false,
    default() {
      return 'theme'
    },
  },
  size: {
    type: Number,
    required: false,
    default() {
      return 16
    },
  },
})

const sizeStyle = _computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  }
})
</script>

<template>
  <div :class="['loading', `color-${color}`]" :style="sizeStyle"/>
</template>

<style scoped lang="scss">
  @use '../../../styles/variables' as variables;

  @keyframes rotate-infinite {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .loading {
    width: 16px;
    height: 16px;
    border: 3px solid transparent;
    border-top: 3px solid map-get(variables.$colors, content-bright);
    border-radius: 100px;
    animation: rotate-infinite .5s linear infinite;

    &.color-theme {
      border-top-color: map-get(variables.$colors, primary);
    }

    &.color-dimmed {
      border-top-color: map-get(variables.$colors, content-dark);
    }
  }
</style>
