<script setup>
import {useAlertStore} from '@toolify/client/src/stores/AlertStore/useAlertStore'
import InputComponent from '../../forms/components/inputs/InputComponent.vue'
import ButtonComponent from '../../ui/components/ButtonComponent.vue'
import {PropertyStrategyThemeType} from '../../tableItems/enum/PropertyStrategyThemeType'

const notificationStore = useAlertStore()
const {
  message,
  color,
  description,
  inputValue,
  buttons,
  layout,
  isCentered,
  showInput,
  iconClass,
  iconColor,
  showCover,
  duration,
} = $(notificationStore.payload)
const {setInput} = $(notificationStore)

const lineAnimationDuration = $computed(() => {
  return (duration / 1000) + 's'
})

const computedBackgroundColor = $computed(() => {
  const styleMap = {
    success: '#08253d',
    error: '#2d0f14',
  }
  return styleMap[color]
})
const lineColor = $computed(() => {
  const styleMap = {
    success: '#558FEF',
    error: '#93000A',
  }
  return styleMap[color]
})
const hoverButtonColor = $computed(() => {
  const styleMap = {
    success: '#0086e6',
    error: '#93000A',
  }
  return styleMap[color]
})
const iconColorStyle = $computed(() => {
  const styleMap = {
    success: 'var(--primary-color)',
    error: 'var(--error-color)',
  }
  return iconColor === 'primary' ? styleMap[color] : 'var(--content-dim-color)'
})

const layoutStyle = $computed(() => {
  return layout === 'horizontal' ? {
    flexDirection: 'column',
    alignItems: 'start',
    padding: '12px',
    gap: '12x',
  } : {
    flexDirection: 'row',
    padding: '12px 16px',
  }
})
const alertWidth = $computed(() => {
  return layout === 'horizontal' ? '400px' : 'auto'
})
const buttonsWidth = $computed(() => {
  return layout === 'horizontal' ? '100%' : 'auto'
})
const onButtonClick = (button) => {
  if (button.onClick) {
    button.onClick()
  }
}
</script>
<template>
  <div>
    <div class="notification">
      <div class="content" :style="layoutStyle">
        <i :class="iconClass" class="icon" v-if="layout !== 'horizontal'"/>
        <div class="message">
          <span>{{message}}</span>
          <div @click="notificationStore.close" v-if="layout === 'horizontal'" class="dismiss-button">
            <i class="close-button fas fa-times"/>
          </div>
        </div>
        <div v-if="description" class="description">{{description}}</div>
        <div class="input" v-if="showInput">
          <InputComponent
            fullWidth
            :theme="PropertyStrategyThemeType.Outlined"
            :value="inputValue"
            @update:modelValue="setInput"
          />
        </div>
        <div :key="inputValue" class="button-row" v-if="buttons.length">
          <ButtonComponent
            v-for="button in buttons"
            :key="button.text"
            :type="button.type"
            :color="button.color"
            :iconClass="button.icon"
            :isDisabled="button.disabled"
            @click="onButtonClick(button)"
          >
            {{button.text}}
          </ButtonComponent>
        </div>
        <div v-if="layout !== 'horizontal'" @click="notificationStore.close" class="dismiss-button">
          <i class="fas fa-times"/>
        </div>
      </div>
      <div v-show="notificationStore.duration" class="line"/>
    </div>
    <div v-if="showCover" class="cover" @click="notificationStore.close"/>
  </div>
</template>
<style scoped lang="scss">
  @use '../../../styles/variables' as variables;

.notification {
  width: v-bind(alertWidth);
  background-color: v-bind(computedBackgroundColor);
  position: absolute;
  z-index: 1000;
  // top: v-bind(top);
  // left: v-bind(left);
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}
.line {
  width: 100%;
  height: 4px;
  background-color: v-bind(lineColor);
  animation: shrink v-bind(lineAnimationDuration) linear;
}
@keyframes shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.content {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding: 0 16px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-row {
  width: v-bind(buttonsWidth);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  gap: 8px;
}
.dismiss-button {
  padding: 8px; 
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: v-bind(hoverButtonColor);
  }
}
.close-button {
  cursor: pointer;
  align-self: flex-end;
}
.input{
  width: 100%;
}
.icon{
  color: v-bind(iconColorStyle);
}
.cover{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
