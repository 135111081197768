import {IToJson} from '@toolify/client/src/types/IToJson'
import {
  IMessageResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IMessageResponseEntity'
import {mapResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResponseEntity'
import {mapFileResponseEntity} from '@toolify/client/src/modules/api/singletons/mapFileResponseEntity'

export function mapMessageResponseEntity(
  message: IToJson<IMessageResponseEntity>,
): IMessageResponseEntity {
  return {
    ...message,
    createdAt: new Date(message.createdAt),
    contentUpdatedAt: message.contentUpdatedAt ? new Date(message.contentUpdatedAt) : null,
    authorEntity: mapResponseEntity(message.authorEntity),
    files: message.files ? message.files.map(file => {
      return mapFileResponseEntity(file)
    }) : [],
    replyMessage: message.replyMessage ? mapMessageResponseEntity(message.replyMessage) : undefined,
  }
}
