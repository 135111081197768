export enum ResourceType {
  Database = 'database',
  DatabaseProperty = 'property',
  DatabaseItem = 'database-item',
  Workspace = 'workspace',
  DatabaseView = 'database-view',
  Conversation = 'conversation',
  Dashboard = 'dashboard',
  Call = 'call',
  DashboardItem = 'dashboard-item',
  Folder = 'folder',
  Automation = 'automation',
  AutomationVersion = 'automation-version',
  DatabaseSlice = 'database-slice',
  ResourceGroup = 'resource-group',
  CloudItem = 'cloud-item',
  DirectConversation = 'direct-conversation',
  ResourceSpace = 'resource-space',
  AutomationApp = 'automation-app',
  AutomationAppItem = 'automation-app-item',
  WebhookListenerDefinition = 'webhook-listener-definition',
  WebhookListener = 'webhook-listener',
  Connection = 'connection',
  ConnectionDefinition = 'connection-definition',
  DynamicFieldDefinition = 'dynamic-field-definition',
}
