<script setup lang="ts">
import LoadingComponent from '@toolify/client/src/modules/ui/components/LoadingComponent.vue'

interface Props {
  text: string
}
const props = defineProps<Props>()
</script>

<template>
  <div class="app-stopped-alert">
    <div class="alert">
      <LoadingComponent color="white"/>
      <div class="text">{{props.text}}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use './../../../styles/variables' as variables;

.app-stopped-alert {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 30px 0;
  @include variables.zIndex(appStoppedAlert);
}

.alert {
  background: map-get(variables.$colors, container-highest);
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 18px;
  display: flex;
  gap: 10px;
  font-weight: 500;
  align-items: center;

  :deep(.loading) {
    width: 10px;
    height: 10px;
    border-width: 3px;
  }
}
</style>
