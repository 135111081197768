import {IResponseEntity} from '@toolify/server/src/services/EntityService/types/IResponseEntity'
import {EntityType} from '@toolify/server/src/services/EntityService/enum/EntityType'

export function getEntityNameByEntity(entity: IResponseEntity) {
  if(entity.entityType === EntityType.User) {
    return (entity as IResponseEntity<EntityType.User>).payload.nick
  }
  if(entity.entityType === EntityType.Bot) {
    return 'Bot'
  }
  return 'System'
}
