import {defineStore} from 'pinia'
import {PermissionType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/PermissionType'
import {
  IPermissionResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IPermissionResponseEntity'
import {$$, $computed, $ref} from '@vue-macros/reactivity-transform/macros'
import {
  IWorkspaceResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IWorkspaceResponseEntity'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {watch} from 'vue'
import {useMeApiStore} from '@toolify/client/src/stores/api/MeApiStore/useMeApiStore'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {ResourceType} from '@toolify/server/src/models/mongoose/PermissionModel/enum/ResourceType'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {
  IUserJoinedResourceEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IUserJoinedResourceEmitPayload'
import {
  IUserLeftResourceEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IUserLeftResourceEmitPayload'
import {
  IUserIdsInResourceEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IUserIdsInResourceEmitPayload'
import {
  IUserResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IUserResponseEntity'
import {
  IUserUpdatedEmitPayload,
} from '@toolify/server/src/services/SocketService/strategies/GlobalSocketEventTypeStrategy/IUserUpdatedEmitPayload'
import {useRoute, useRouter} from 'vue-router'
import {useToast} from 'vue-toast-notification'
import {useActivityItemSocketListener} from '@toolify/client/src/composables/useActivityItemSocketListener'
import {ActivityItemType} from '@toolify/server/src/models/mongoose/ActivityItemModel/enum/ActivityItemType'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'

export const useWorkspaceStore = defineStore('workspace', () => {
  const authStore = useAuthStore()
  const meApiStore = useMeApiStore()
  const socketStore = useSocketStore()
  const router = useRouter()
  const toast = useToast()
  const route = useRoute()

  const currentWorkspace = $ref<IWorkspaceResponseEntity>(null)
  let myWorkspaces = $ref<IWorkspaceResponseEntity[]>(null)
  let workspacePermissionMap = $ref<Map<PermissionType, unknown>>(null)
  let usersInResourceMap = $ref<Map<string, IUserResponseEntity[]>>(new Map())
  let hasJoinedAllWorkspaces = $ref(false)
  function initialize() {
    socketStore.socket.on(GlobalSocketEventType.UserJoinedResource, onUserJoinedResource)
    socketStore.socket.on(GlobalSocketEventType.UserLeftResource, onUserLeftResource)
    socketStore.socket.on(GlobalSocketEventType.UsersInResource, onUsersInResource)
    socketStore.socket.on(GlobalSocketEventType.UserUpdated, onUserUpdated)
    start()
  }

  function dispose() {
    socketStore.socket.off(GlobalSocketEventType.UserJoinedResource, onUserJoinedResource)
    socketStore.socket.off(GlobalSocketEventType.UserLeftResource, onUserLeftResource)
    socketStore.socket.off(GlobalSocketEventType.UsersInResource, onUsersInResource)
    socketStore.socket.off(GlobalSocketEventType.UserUpdated, onUserUpdated)
    stop()
  }

  const resourcePathFilter = {
    resourceType: () => ResourceType.Workspace,
    resourceId: () => currentWorkspace?.id,
  }

  const {start, stop} = useActivityItemSocketListener(ActivityItemType.WorkspaceDeleted, ({targetResource}) => {
    if(!route.meta.requireWorkspace) {
      return
    }
    toast.open({
      type: 'warning',
      message: 'That workspace has been deleted',
    })
    router.push({
      name: RouteName.ChooseWorkspace,
    })
    leaveWorkspaceRoom(targetResource.resourceId)
  }, resourcePathFilter, {
    useLifecycleHooks: false,
  })

  function setCurrentWorkspacePermissionMap(permissions: Pick<IPermissionResponseEntity, 'type'|'value'>[]) {
    const newPermissionMap = new Map<PermissionType, unknown>()
    for(const permission of permissions) {
      newPermissionMap.set(permission.type, permission.value)
    }
    workspacePermissionMap = newPermissionMap
  }

  function hasPermission(permissionType: PermissionType): boolean {
    const permissionMap = workspacePermissionMap
    if(!permissionMap?.has(permissionType)) {
      return false
    }
    const value = permissionMap.get(permissionType)
    return !!value
  }

  function joinAllWorkspaces() {
    if(!myWorkspaces || hasJoinedAllWorkspaces) {
      return
    }
    for(const workspace of myWorkspaces) {
      socketStore.socket.emit(GlobalSocketEventType.JoinResource, {
        resourceType: ResourceType.Workspace,
        resourceId: workspace.id,
      })
    }
  }

  function leaveAllWorkspaces() {
    if(!myWorkspaces) {
      return
    }
    for(const workspace of myWorkspaces) {
      leaveWorkspaceRoom(workspace.id)
    }
  }

  function leaveWorkspaceRoom(workspaceId: string) {
    socketStore.socket.emit(GlobalSocketEventType.LeaveResource, {
      resourceType: ResourceType.Workspace,
      resourceId: workspaceId,
    })
  }

  watch(() => {
    return authStore.currentUser?.id
  }, async (value, oldValue) => {
    if(value === oldValue) {
      return
    }
    if(oldValue) {
      leaveAllWorkspaces()
      // clean sockets
    }
    if(!value) {
      myWorkspaces = null
      return
    }

    const response = await meApiStore.getMyWorkspaces()
    myWorkspaces = response.workspaces
    joinAllWorkspaces()
  })

  watch(() => {
    return socketStore.isConnected
  }, value => {
    if(value) {
      joinAllWorkspaces()
    } else {
      hasJoinedAllWorkspaces = false
    }
  })

  const myWorkspaceIds = $computed(() => {
    if(!myWorkspaces) {
      return []
    }
    return myWorkspaces.map(workspace => workspace.id)
  })

  function onUserJoinedResource(payload: IUserJoinedResourceEmitPayload) {
    if(!myWorkspaceIds.includes(payload.resourceId) || payload.resourceType !== ResourceType.Workspace) {
      return
    }
    addUserToUsersInResource(payload.user, payload.resourceId)
  }

  function onUserLeftResource(payload: IUserLeftResourceEmitPayload) {
    if(!myWorkspaceIds.includes(payload.resourceId) || payload.resourceType !== ResourceType.Workspace) {
      return
    }
    removeUserFromUsersInResource(payload.userId, payload.resourceId)
  }

  function onUsersInResource(payload: IUserIdsInResourceEmitPayload) {
    if(!myWorkspaceIds.includes(payload.resourceId)) {
      return
    }
    setUsersInResource(payload.resourceId, payload.users)
  }

  function onUserUpdated(payload: IUserUpdatedEmitPayload) {
    for(const workspaceId of myWorkspaceIds) {
      const newMap = new Map(usersInResourceMap)
      if(!newMap.has(workspaceId)) {
        continue
      }
      const users = newMap.get(workspaceId)
      const userIndex = users.findIndex(user => user.id === payload.userId)
      if(userIndex === -1) {
        continue
      }
      users[userIndex] = payload.user
      newMap.set(workspaceId, users)
      usersInResourceMap = newMap
    }
  }

  function addUserToUsersInResource(user: IUserResponseEntity, workspaceId: string) {
    const newMap = new Map(usersInResourceMap)
    if(!newMap.has(workspaceId)) {
      newMap.set(workspaceId, [])
    }
    const users = newMap.get(workspaceId)
    users.push(user)
    usersInResourceMap = newMap
  }

  function removeUserFromUsersInResource(userId: string, workspaceId: string) {
    const newMap = new Map(usersInResourceMap)
    if(!newMap.has(workspaceId)) {
      return
    }
    let users = newMap.get(workspaceId)
    users = users.filter(user => {
      return user.id !== userId
    })
    newMap.set(workspaceId, users)
    usersInResourceMap = newMap
  }

  function setUsersInResource(workspaceId: string, users: IUserResponseEntity[]) {
    const newMap = new Map(usersInResourceMap)
    newMap.set(workspaceId, users)
    usersInResourceMap = newMap
  }

  const usersInCurrentWorkspace = $computed(() => {
    return usersInResourceMap.get(currentWorkspace?.id)
  })

  return $$({
    currentWorkspace,
    myWorkspaces,
    workspacePermissionMap,
    setCurrentWorkspacePermissionMap,
    hasPermission,
    usersInCurrentWorkspace,
    initialize,
    dispose,
    usersInResourceMap,
    leaveWorkspaceRoom,
  })
})
