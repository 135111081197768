import {defineStore} from 'pinia'
import {$$, $computed, $ref} from '@vue-macros/reactivity-transform/macros'
import {
  useDirectConversationApiStore,
} from '@toolify/client/src/stores/api/DirectConversationApiStore/useDirectConversationApiStore'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {
  IDirectConversationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDirectConversationResponseEntity'
import {EntityType} from '@toolify/server/src/services/EntityService/enum/EntityType'
import {RouteName} from '@toolify/client/src/setup/router/enum/RouteName'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {mapNotificationResponseEntity} from '@toolify/client/src/modules/api/singletons/mapNotificationResponseEntity'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {ConversationType} from '@toolify/server/src/models/mongoose/MessageModel/enum/ConversationType'
import {
  INewMessageNotificationTypeInterfaces,
} from '@toolify/server/src/services/NotificationService/strategies/INewMessageNotificationTypeInterfaces'
import {watch} from 'vue'
import {getEntityNameByEntity} from '@toolify/client/src/modules/entities/functions/getEntityNameByEntity'

export const useDirectConversationStore = defineStore('directConversation', () => {
  const directConversationApiStore = useDirectConversationApiStore()
  const authStore = useAuthStore()
  const socketStore = useSocketStore()

  let directConversations = $ref<IDirectConversationResponseEntity[]>(null)

  async function initialize() {
    socketStore.socket.on(GlobalSocketEventType.NotificationCreated, onNotificationCreated)
  }
  function dispose() {
    socketStore.socket.off(GlobalSocketEventType.NotificationCreated, onNotificationCreated)
  }

  watch(() => {
    return authStore.currentUser?.id
  }, async (value) => {
    if(value) {
      const getDirectConversationsResponse = await directConversationApiStore.getDirectConversations()
      directConversations = getDirectConversationsResponse.directConversations
    } else {
      directConversations = null
    }
  })

  async function onNotificationCreated({notification}: {
    notification: INotificationResponseEntity<NotificationType>
  }) {
    const mappedNotification = mapNotificationResponseEntity(notification as unknown as IToJson<INotificationResponseEntity<NotificationType>>)
    if(mappedNotification.type === NotificationType.NewMessage) {
      const newMessageNotification = mappedNotification as INotificationResponseEntity<NotificationType.NewMessage>
      if(newMessageNotification.payload.conversationType === ConversationType.Direct) {
        const newMessagePayload = newMessageNotification.payload as INewMessageNotificationTypeInterfaces<ConversationType.Direct>['payload']
        const directConversationId = newMessagePayload.conversationPayload.directConversationId
        const foundDirectConversation = directConversations?.find(directConversation => {
          return directConversation.id === directConversationId
        })

        if(foundDirectConversation) {
          directConversations = directConversations.map(directConversation => {
            if(directConversation.id === directConversationId) {
              return {
                ...directConversation,
                lastActivityAt: new Date(),
                unreadNotificationCount: directConversation.unreadNotificationCount + 1,
                latestMessageText: newMessagePayload.truncatedContent,
              }
            }
            return directConversation
          })
        } else {
          const getDirectConversationResponse = await directConversationApiStore.getDirectConversation(directConversationId)
          const newDirectConversation = getDirectConversationResponse.directConversation
          directConversations = [
            ...directConversations,
            newDirectConversation,
          ]
        }
      }
    }
  }

  function markLocalDirectConversationAsRead(directConversationId: string) {
    if(directConversations) {
      directConversations = directConversations.map(directConversation => {
        if(directConversation.id === directConversationId) {
          return {
            ...directConversation,
            unreadNotificationCount: 0,
            readAt: new Date(),
          }
        }
        return directConversation
      })
    }
  }

  const mappedDirectConversations = $computed(() => {
    if(!directConversations) {
      return null
    }
    return directConversations.sort((a, b) => {
      if(!a.lastActivityAt) {
        return 1
      }
      if(!b.lastActivityAt) {
        return -1
      }
      return b.lastActivityAt.getTime() - a.lastActivityAt.getTime()
    }).map(directConversation => {
      const maxAvatarCount = directConversation.totalMemberCount > 4 ? 3 : directConversation.totalMemberCount

      const cachedFirstEntitiesExceptMe = directConversation.cachedFirstEntities.filter(entity => {
        if(entity.entityType !== EntityType.User) {
          return true
        }
        if(!directConversation.isGroup) {
          return entity.entityId !== authStore.currentUser?.id
        }
        return true
      })
      let isUnread = false
      if(directConversation.lastActivityAt) {
        isUnread = true
      }
      if(directConversation.lastActivityAt && directConversation.readAt) {
        isUnread = directConversation.lastActivityAt > directConversation.readAt
      }
      return {
        name: directConversation.name || (cachedFirstEntitiesExceptMe.length ? cachedFirstEntitiesExceptMe.map(entity => {
          return getEntityNameByEntity(entity)
        }).join(', ') : 'Untitled conversation'),
        avatarEntities: cachedFirstEntitiesExceptMe.slice(0, maxAvatarCount),
        thumbnailCount: Math.min(4, cachedFirstEntitiesExceptMe.length),
        totalMemberCount: directConversation.totalMemberCount,
        id: directConversation.id,
        latestMessageText: directConversation.latestMessageText || 'Empty conversation',
        to: {
          name: RouteName.DirectConversation,
          params: {
            directConversationId: directConversation.id,
          },
        },
        isUnread,
        unreadCount: directConversation.unreadNotificationCount,
      }
    })
  })

  return $$({
    initialize,
    dispose,
    mappedDirectConversations,
    markLocalDirectConversationAsRead,
  })
})
