import {defineStore} from 'pinia'
import {$$, $ref} from '@vue-macros/reactivity-transform/macros'

export const useCacheStore = defineStore('cache', () => {
  const cacheByIdMap = $ref(new Map())

  function getCacheItem<T = unknown>(id: string): T {
    // return cacheByIdMap.get(id)
    return null
  }

  function setCacheItem<T = unknown>(id: string, item: T) {
    // cacheByIdMap.set(id, item)
  }

  return $$({
    getCacheItem,
    setCacheItem,
  })
})
