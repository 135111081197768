import {defineStore} from 'pinia'
import {IKeyboardShortcutStoreState} from './types/IKeyboardShortcutStoreState'
import {IKeyboardShortcut} from '@toolify/client/src/stores/KeyboardShortcutStore/types/IKeyboardShortcut'
import {KeyboardModifierKeyTypes} from '@toolify/client/src/stores/KeyboardShortcutStore/enum/KeyboardModifierKeyTypes'
import {IKeyboardKeyCombination} from '@toolify/client/src/stores/KeyboardShortcutStore/types/IKeyboardKeyCombination'
import {KeyboardKeyCodeMap} from '@toolify/client/src/stores/KeyboardShortcutStore/maps/KeyboardKeyCodeMap'

export const useKeyboardShortcutStore = defineStore('keyboardShortcut', {
  state: () => {
    return {
      currentId: 0,
      shortcuts: [],
      scopes: [],
    } as IKeyboardShortcutStoreState
  },
  actions: {
    createScope(scope: symbol) {
      this.scopes.push(scope)
    },
    deleteScope(scope: symbol) {
      this.scopes = this.scopes.filter(currentScope => {
        return currentScope !== scope
      })
    },
    registerShortcuts(shortcuts: IKeyboardShortcut[], scope: symbol): symbol[] {
      return shortcuts.map(shortcut => {
        return this.registerShortcut(shortcut, scope)
      })
    },
    registerShortcut(shortcut: IKeyboardShortcut, scope: symbol): symbol {
      const id = Symbol()
      this.shortcuts.push({
        shortcut,
        id,
        scope,
      })
      return id
    },
    deleteShortcutsByIds(ids: symbol[]): void {
      this.shortcuts = this.shortcuts.filter(shortcut => {
        return !ids.includes(shortcut.id)
      })
    },
    deleteShortcutById(id: symbol): void {
      this.deleteShortcutsByIds([id])
    },
    initializeListeners() {
      document.addEventListener('keydown', this.keyDownListener)
      // document.addEventListener('keyup', this.keyUpListener)
    },
    dispose() {
      document.removeEventListener('keydown', this.keyDownListener)
      // document.removeEventListener('keyup', this.keyUpListener)
    },
    getKeyCombinationOutOfEvent(event: KeyboardEvent): IKeyboardKeyCombination {
      const items = [
        {
          keyType: KeyboardModifierKeyTypes.Alt,
          value: event.altKey,
        },
        {
          keyType: KeyboardModifierKeyTypes.Shift,
          value: event.shiftKey,
        },
        {
          keyType: KeyboardModifierKeyTypes.Control,
          value: event.ctrlKey,
        },
        // TODO: Implement
        // {
        //   keyType: KeyboardModifierKeyTypes.Command,
        //   value: event.altKey
        // },
        // {
        //   keyType: KeyboardModifierKeyTypes.Option,
        //   value: event.altKey
        // }
      ]
      const modifierKeys = items.filter(item => item.value).map(item => item.keyType)
      const key = this.mapKeyCodeToKey(event.code)
      return [...modifierKeys, key]
    },
    mapKeyCodeToKey(keyCode: string): string {
      return KeyboardKeyCodeMap.get(keyCode) || keyCode
    },
    compareTwoKeyCombinations(firstCombination: IKeyboardKeyCombination, secondCombination: IKeyboardKeyCombination): boolean {
      return firstCombination.sort().join(',') === secondCombination.sort().join(',') || firstCombination[0] === null || secondCombination[0] === null
    },
    keyDownListener(event: KeyboardEvent) {
      const shortcutsInCurrentScope = this.shortcuts
      const eventCombination = this.getKeyCombinationOutOfEvent(event)
      const matchingShortcuts = shortcutsInCurrentScope.filter(shortcut => {
        return this.compareTwoKeyCombinations(eventCombination, shortcut.shortcut.keys)
      }).reverse()
      if(!matchingShortcuts.length) {
        return
      }
      matchingShortcuts[0].shortcut.callback()
      if(matchingShortcuts[0].shortcut.keys.includes(null)) {
        return
      }
      event.preventDefault()
    },
  },
  getters: {
    currentScope(state): symbol {
      return state.scopes[state.scopes.length - 1]
    },
    // shortcutsInCurrentScope(): IKeyboardShortcutItem[] {
    //   return this.shortcuts.filter(shortcut => {
    //     return shortcut.scope === this.currentScope
    //   })
    // },
  },
})
