import {defineStore} from 'pinia'
import {GlobalSocketEventType} from '@toolify/server/src/services/SocketService/enum/GlobalSocketEventType'
import {useSocketStore} from '@toolify/client/src/stores/SocketStore/useSocketStore'
import {
  INotificationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/INotificationResponseEntity'
import {NotificationType} from '@toolify/server/src/models/mongoose/NotificationModel/enum/NotificationType'
import {IResponseEntity} from '@toolify/server/src/services/EntityService/types/IResponseEntity'
import {EntityType} from '@toolify/server/src/services/EntityService/enum/EntityType'
import {$ref, $$, $computed} from '@vue-macros/reactivity-transform/macros'
import {useNativeAppStore} from '@toolify/client/src/stores/AppTitlebarStore/useNativeAppStore'
import {isElectron} from '@toolify/client/src/modules/electron/singletons/isElectron'
import {
  NotificationTypeStrategyFactory,
} from '@toolify/client/src/stores/NotificationStore/factories/NotificationTypeStrategyFactory'
import {useNotificationApiStore} from '@toolify/client/src/stores/api/NotificationApiStore/useNotificationApiStore'

import {mapNotificationResponseEntity} from '@toolify/client/src/modules/api/singletons/mapNotificationResponseEntity'
import {IToJson} from '@toolify/client/src/types/IToJson'
import {watch} from 'vue'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'
import {
  IFileVariantResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IFileVariantResponseEntity'
import {
  IImageToImageFileConversionTypeStrategyInterfaces,
} from '@toolify/server/src/services/FileVariantService/strategies/FileConversionTypeStrategies/ImageToImageFileConversionTypeStrategy/types/IImageToImageFileConversionTypeStrategyInterfaces'
import {FileVariantType} from '@toolify/server/src/models/mongoose/FileVariantModel/enum/FileVariantType'
import {getEntityNameByEntity} from '@toolify/client/src/modules/entities/functions/getEntityNameByEntity'

export const useNotificationStore = defineStore('notification', () => {
  const socketStore = useSocketStore()
  const nativeAppStore = useNativeAppStore()
  const notificationApiStore = useNotificationApiStore()
  const authStore = useAuthStore()

  let areNotificationsSupported = $ref(false)
  let hasPermission = $ref(false)
  let checkPermissionsInterval = $ref(null)
  let unseenNotificationsCount = $ref(0)
  let notifications = $ref<INotificationResponseEntity<NotificationType>[]>([])

  function initialize() {
    socketStore.socket.on(GlobalSocketEventType.NotificationCreated, onNotificationCreated)
    areNotificationsSupported = window && 'Notification' in window
    checkPermission()
    checkPermissionsInterval = setInterval(() => {
      checkPermission()
    }, 5000)
  }

  function checkPermission() {
    if(!areNotificationsSupported) {
      hasPermission = false
    } else {
      if('permission' in Notification && Notification.permission === 'granted') {
        hasPermission = true
      } else {
        hasPermission = false
      }
    }
  }

  function dispose() {
    socketStore.socket.off(GlobalSocketEventType.NotificationCreated, onNotificationCreated)
    clearInterval(checkPermissionsInterval)
  }

  async function fetchUnseenNotificationsCount() {
    unseenNotificationsCount = await notificationApiStore.getUnseenNotificationsCount()
  }

  async function fetchNotifications() {
    notifications = await notificationApiStore.getNotifications()
  }

  async function onNotificationCreated({notification}: {
    notification: INotificationResponseEntity<NotificationType>
  }) {
    if(isElectron()) {
      nativeAppStore.updateNotificationCount(1)
    }
    const mappedNotification = mapNotificationResponseEntity(notification as unknown as IToJson<INotificationResponseEntity<NotificationType>>)
    if(!mappedNotification.isTransient) {
      notifications.push(mappedNotification)
      unseenNotificationsCount++
    }

    const authorEntity = notification.authorEntity
    type ImageFileVariant = IFileVariantResponseEntity<IImageToImageFileConversionTypeStrategyInterfaces['payload']>
    const authorAvatarUrl = $computed(() => {
      if(authorEntity.entityType !== EntityType.User) {
        return ''
      }
      return (authorEntity as IResponseEntity<EntityType.User>).payload?.completedAvatarFileVariants?.find(fileVariant => {
        if(fileVariant.type !== FileVariantType.Image) {
          return false
        }
        return (fileVariant as ImageFileVariant).payload.height === 64
          && (fileVariant as ImageFileVariant).payload.width === 64
      })?.url || ''
    })
    const authorNick = getEntityNameByEntity(authorEntity)
    let shouldPlayNotificationSound = true
    const icon = authorAvatarUrl
    const notificationTypeStrategyFactory = new NotificationTypeStrategyFactory()
    const strategy = notificationTypeStrategyFactory.getStrategy(notification.type)
    const {body, title, sound} = strategy.getBrowserNotification(notification, authorNick)
    try {
      const audio = new Audio(sound)
      await audio.play()
      shouldPlayNotificationSound = false
    } catch(e) {
      // Sound was not played
    }

    if(!areNotificationsSupported) {
      // eslint-disable-next-line no-console
      return console.log('Notifications unsupported')
    }
    if(!hasPermission) {
      // eslint-disable-next-line no-console
      return console.log('No permission to show notifications')
    }
    if(title) {
      const browserNotification = new Notification(title, {
        body,
        icon,
        silent: !shouldPlayNotificationSound,
      })
    }
  }

  async function requestPermission() {
    if(!hasPermission) {
      await Notification.requestPermission()
    }
  }

  watch(() => {
    return authStore.currentUser?.id
  }, async (value, oldValue) => {
    if(value === oldValue) {
      return
    }
    if(oldValue) {
      unseenNotificationsCount = 0
      notifications = []
    }
    if(!value) {
      return
    }
    fetchUnseenNotificationsCount()
    fetchNotifications()
  })

  return $$({
    areNotificationsSupported,
    hasPermission,
    unseenNotificationsCount,
    fetchNotifications,
    initialize,
    dispose,
    requestPermission,
  })
})
