import {defineStore} from 'pinia'
import {IAlertStoreState} from './types/IAlertStoreState'
import {$ref} from '@vue-macros/reactivity-transform/macros'

export const useAlertStore = defineStore('errorPage', {
  state: () => {
    return {
      isOpen: false,
      payload: {
        message: '',
        description: '',
        color: 'success',
        duration: 1000,
        isCentered: false,
        layout: 'horizontal',
        buttons: [],
        inputValue: '',
        showInput: false,
        iconClass: '',
        iconColor: 'primary',
        showCover: false,
      },
    } as IAlertStoreState
  },
  actions: {
    open(
      payload: Partial<IAlertStoreState['payload']>,
    ) {
      this.setDefaultPayload()
      this.payload.inputValue = ''
      this.isOpen = true
      const newButtons = $ref(payload?.buttons)
      this.payload = {
        ...this.payload,
        ...payload,
        buttons: newButtons || [],
      }
      if(!payload.duration) return
      setTimeout(() => {
        this.isOpen = false
      }, payload.duration)
    },
    close() {
      this.isOpen = false
    },
    setInput (value: string) {
      this.payload.inputValue = value
    },
    setDefaultPayload() {
      this.payload = {
        message: '',
        description: '',
        color: 'success',
        duration: 1000,
        isCentered: false,
        layout: 'horizontal',
        buttons: [],
        inputValue: '',
        showInput: false,
        iconClass: '',
        iconColor: 'primary',
        showCover: false,
      }
    },
  },
})

