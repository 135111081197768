<script setup lang="ts">
import LoadingComponent from '@toolify/client/src/modules/ui/components/LoadingComponent.vue'

interface Props {
  type?: 'filled'|'text-primary'|'text-secondary'|'outlined'|'elevated-primary'|'elevated-secondary'|'tonal'|'transparent'|'secondary'|'text-error'
  iconClass?: string
  isLoading?: boolean
  isDisabled?: boolean
  round?: boolean
  color?: string
  hasArrow?: boolean
  hasFocus?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  type: 'filled',
  iconClass: null,
  isLoading: false,
  round: false,
  color: 'theme',
  hasArrow: false,
  hasFocus: false,
  isDisabled: false,
})
</script>

<template>
  <button class="button" :class="[`type-${props.type}`, `color-${props.color}`, {'is-loading': props.isLoading, 'is-disabled': props.isDisabled, 'is-round': props.round, 'has-focus': props.hasFocus}]">
    <LoadingComponent v-if="props.isLoading" color="white"/>
    <i :class="props.iconClass" v-if="props.iconClass"/>
    <div class="content" v-if="$slots.default">
      <slot/>
    </div>
    <i class="arrow fa fa-caret-down" v-if="props.hasArrow"/>
  </button>
</template>

<style scoped lang="scss">
  @use '../../../styles/variables' as variables;

  .button {
    border-radius: variables.$borderRadius;
    background: map-get(variables.$colors, primary);
    color: map-get(variables.$colors, on-primary);
    border: none;
    height: 36px;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 500;

    &.is-round {
      border-radius: 100px;
      padding: 0px 20px;
    }

    &.color-red {
      background: hsl(4deg 90% 61%);
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      background: hsl(0deg 0% 100% / 15%);
      left: 0;
      top: 0;
      //transition: all .1s ease;
      //transition: opacity .1s ease-in-out;
    }

    &.type-text-primary {
      background: transparent;
      color: map-get(variables.$colors, primary);

      &:hover {
        color: map-get(variables.$colors, content-bright);
      }
      &:after {
        background: hsl(0deg 0% 100% / 5%);
      }

      &.is-loading {
        background: transparent;
      }
    }

    &.type-text-secondary {
      background: transparent;
      color: map-get(variables.$colors, content-dim);

      &:hover {
        color: map-get(variables.$colors, content-bright);
      }
      &:after {
        background: hsl(0deg 0% 100% / 5%);
      }

      &.is-loading {
        background: transparent;
      }
    }

    &.type-outlined {
      background: transparent;
      //border: 1px solid map-get(variables.$colors, content-dark);
      //color: map-get(variables.$colors, primary);

      &:after {
        background: hsl(0deg 0% 100% / 5%);
      }

      &.is-loading {
        background: transparent;
      }
    }

    &.type-elevated-primary{
      background: map-get(variables.$colors, container);
      color: map-get(variables.$colors, primary);

      &:after {
        background: hsl(0deg 0% 100% / 5%);
      }

      &.is-loading {
        background: transparent;
      }
    }

    &.type-elevated-secondary{
      background: map-get(variables.$colors, container);
      color: map-get(variables.$colors, content-bright);
      &:after {
        background: hsl(0deg 0% 100% / 5%);
      }

      &.is-loading {
        background: transparent;
      }
    }

    &.type-text-error{
      background: transparent;
      color: map-get(variables.$colors, error);
      &:hover {
        color: map-get(variables.$colors,on-error-container);
        background: transparent;
      }
      &:after {
        background: transparent;
      }
    }

    &.type-tonal {
      background: map-get(variables.$colors, container-high);
      color: hsl(0deg 0% 100% / 66%);
    }

    &:hover:after, &.has-focus:after {
      opacity: 1;
    }


    &.is-disabled {
      pointer-events: none;
      background: hsl(0deg 0% 100% / 10%);
      color: hsl(0deg 0% 100% / 33%);
    }
  }

  i {
    font-size: 13px;
  }

  .button.is-loading  {
    background: hsl(0deg 0% 100% / 10%);
    cursor: default;
    pointer-events: none;

    &:hover:after {
      opacity: 0;
    }

    & i, & .content {
      opacity: 0;
    }
  }

  .loading {
    position: absolute;
    width: 10px;
    height: 10px;
  }

  .arrow {
    font-size: 12px;
    color: rgb(255 255 255 / 66%);
  }

  .button.has-focus .arrow {
    transform: rotate(180deg);
  }
</style>
