import {IToJson} from '@toolify/client/src/types/IToJson'

import {mapMessageResponseEntity} from '@toolify/client/src/modules/api/singletons/mapMessageResponseEntity'
import {
  IDirectConversationResponseEntity,
} from '@toolify/server/src/adapters/EntityApiResponseAdapter/types/responseEntities/IDirectConversationResponseEntity'
import {mapResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResponseEntity'

export function mapDirectConversationResponseEntity(conversation: IToJson<IDirectConversationResponseEntity>): IDirectConversationResponseEntity {
  return {
    ...conversation,
    latestMessage: conversation.latestMessage ? mapMessageResponseEntity(conversation.latestMessage) : null,
    cachedFirstEntities: conversation.cachedFirstEntities ? conversation.cachedFirstEntities.map(entity => {
      return mapResponseEntity(entity) // TODO: It should not be "actor"
    }) : null,
    lastActivityAt: conversation.lastActivityAt ? new Date(conversation.lastActivityAt) : null,
    readAt: conversation.readAt ? new Date(conversation.readAt) : null,
  } as IDirectConversationResponseEntity // TODO: TS: Remove "as ..."
}

