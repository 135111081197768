<script setup lang="ts">import { computed as _computed } from 'vue';


import AppContent from '@toolify/client/src/modules/app/components/AppContent.vue'
import {isElectron} from '@toolify/client/src/modules/electron/singletons/isElectron'
import NativeWindowHeaderComponent
  from '@toolify/client/src/modules/electron/components/NativeWindowHeaderComponent.vue'
import NativeAppUpdateBarComponent
  from '@toolify/client/src/modules/electron/components/NativeAppUpdateBarComponent.vue'
import {useAppUpdateStore} from '@toolify/client/src/stores/AppUpdateStore/useAppUpdateStore'
import {useAuthStore} from '@toolify/client/src/stores/AuthStore/useAuthStore'

import {
  ThemeType,
} from '@toolify/server/src/services/UserSettingsService/strategies/ThemeUserSettingStrategy/enum/ThemeType'
import {useHead} from '@unhead/vue'

const hasNativeHeader = isElectron()
const appUpdateStore = useAppUpdateStore()
const authStore = useAuthStore()

const currentTheme = _computed(() => {
  return authStore.currentUser?.settings?.theme || ThemeType.Dark
})
const appBrandingName = ENV.AppBrandingName
useHead({
  title: `${appBrandingName} - Most flexible CRM in the world`,
  htmlAttrs: {
    lang: 'en',
  },
})
</script>

<template>
  <div
    class="app-wrapper"
    :class="{'has-native-header': hasNativeHeader, 'show-app-update-bar': appUpdateStore.isUpdateAvailable}"
    :data-theme="currentTheme"
  >
    <div class="floating-container" id="tooltip-divs"/>
    <div class="floating-container" id="dropdown-divs"/>
    <div id="teleported"/>
    <NativeWindowHeaderComponent v-if="hasNativeHeader"/>
    <NativeAppUpdateBarComponent v-if="appUpdateStore.isUpdateAvailable"/>
    <AppContent/>
  </div>
</template>

<style scoped lang="scss">
@use '../styles/variables' as variables;

.app-wrapper {
  width: 100%;
  height: 100%;
  color: map-get(variables.$colors, 'content-normal');
  background: map-get(variables.$colors, 'surface');
  font-size: var(--default-font-size);

  &:deep {
    input,
    textarea,
    button {
      font-size: var(--default-font-size);
    }
  }


  &.has-native-header {
    display: grid;
    grid-template-rows: auto 1fr;

    &.show-app-update-bar {
      grid-template-rows: auto auto 1fr;
    }
  }
}

.floating-container {
  position: absolute;
  left: 20px;
  top: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow: hidden;

  .app-wrapper.has-native-header & {
    top: calc(20px + 32px);
    height: calc(100% - 40px - 32px);
  }

  .app-wrapper.show-app-update-bar & {
    top: calc(20px + 32px + 32px);
    height: calc(100% - 40px - 32px - 32px);
  }
}
</style>
