import {createI18n} from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import {LocaleType} from '@toolify/server/src/services/UserSettingsService/strategies/LocaleUserSettingStrategy/enum/LocaleType'

// TODO: Refactor
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- needed for i18n to work in .ts files
export const i18n:any = createI18n({
  locale: LocaleType.English,
  messages,
  globalInjection: true,
  legacy: true,
})

