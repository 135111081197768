import {defineStore} from 'pinia'
import {useApiRequestStore} from '../../ApiRequestStore/useApiRequestStore'
import {
  IGetResourceGroupsRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceGroupController/validators/GetResourceGroups/types/IGetResourceGroupsRequestInterfaces'
import {mapResourceGroupResponseEntity} from '@toolify/client/src/modules/api/singletons/mapResourceGroupResponseEntity'
import {
  ICreateResourceGroupRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceGroupController/validators/CreateResourceGroup/types/ICreateResourceGroupRequestInterfaces'
import {
  IUpdateResourceGroupPositionRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceGroupController/validators/UpdateResourceGroupPosition/types/IUpdateResourceGroupPositionRequestInterfaces'
import {
  IUpdateResourceGroupNameRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceGroupController/validators/UpdateResourceGroupName/types/IUpdateResourceGroupNameRequestInterfaces'
import {
  IDeleteResourceGroupRequestInterfaces,
} from '@toolify/server/src/controllers/ResourceGroupController/validators/DeleteResourceGroup/types/IDeleteResourceGroupRequestInterfaces'

export const useResourceGroupApiStore = defineStore('resourceGroupApi', {
  actions: {
    async getResourceGroups(
      workspaceId: string,
    ): Promise<IGetResourceGroupsRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceGroups} = await apiRequestStore.get<IGetResourceGroupsRequestInterfaces>(`/workspace/${workspaceId}/resource-groups`)
      return {
        resourceGroups: resourceGroups.map(activityItem => mapResourceGroupResponseEntity(activityItem)),
      }
    },

    async createResourceGroup(
      workspaceId: string,
      name?: string,
      resourceSpaceId?: string,
    ): Promise<ICreateResourceGroupRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceGroup} = await apiRequestStore.post<ICreateResourceGroupRequestInterfaces>(`/workspace/${workspaceId}/resource-group`, {
        data: {
          name,
          resourceSpaceId,
        },
      })
      return {
        resourceGroup: mapResourceGroupResponseEntity(resourceGroup),
      }
    },

    async updateResourceGroupPosition(
      resourceGroupId: string,
      offset: number,
      resourceSpaceId?: string,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.put<IUpdateResourceGroupPositionRequestInterfaces>(`/resource-group/${resourceGroupId}/position`, {
        data: {
          offset,
          resourceSpaceId,
        },
      })
    },

    async updateResourceGroupName(
      resourceGroupId: string,
      name: string,
    ): Promise<ICreateResourceGroupRequestInterfaces['response']> {
      const apiRequestStore = useApiRequestStore()
      const {resourceGroup} = await apiRequestStore.put<IUpdateResourceGroupNameRequestInterfaces>(`/resource-group/${resourceGroupId}/name`, {
        data: {
          name,
        },
      })
      return {
        resourceGroup: mapResourceGroupResponseEntity(resourceGroup),
      }
    },

    async deleteResourceGroup(
      resourceGroupId: string,
      withContent?: boolean,
    ): Promise<void> {
      const apiRequestStore = useApiRequestStore()
      await apiRequestStore.delete<IDeleteResourceGroupRequestInterfaces>(`/resource-group/${resourceGroupId}`,{
        data: {
          withContent,
        },
      })
    },
  },
})
