import {Router} from 'vue-router'
import {Pinia} from 'pinia'
import {initializeAppRouterHookFactory} from './router/hooks/initializeAppRouterHook'
import {ensureAuthRouterHookFactory} from './router/hooks/ensureAuthRouterHook'
import {markFirstRouteAsInitializedRouterHookFactory} from './router/hooks/markFirstRouteAsInitializedRouterHook'
import {ensureWorkspaceRouterHookFactory} from './router/hooks/ensureWorkspaceRouterHook'
import {setTransitionNameRouterHookFactory} from './router/hooks/setTransitionNameRouterHook'
import {preventNavigationRouterHookFactory} from './router/hooks/preventNavigationRouterHook'
import {finishPageLoadingRouterHookFactory} from '@toolify/client/src/setup/router/hooks/PageLoadingHooks/finishPageLoadingRouterHook'
import {startPageLoadingRouterHookFactory} from '@toolify/client/src/setup/router/hooks/PageLoadingHooks/startPageLoadingRouterHook'
import {
  startDebounceTimerRouterHookFactory,
} from '@toolify/client/src/setup/router/hooks/DebounceTimerHooks/startDebounceTimerRouterHook'
import {
  awaitDebounceTimerRouterHookFactory,
} from '@toolify/client/src/setup/router/hooks/DebounceTimerHooks/awaitDebounceTimerRouterHook'
import * as Sentry from '@sentry/vue'
import {App} from '@vue/runtime-core'
import {
  beforeDatabaseViewPageRouterHookFactory,
} from '@toolify/client/src/pages/DatabaseViewPage/beforeDatabaseViewPageRouterHook'

export function runPostClientSetupTasks(
  router: Router,
  pinia: Pinia,
  vueApp: App,
) {
  const isDebounceEnabled = false
  router.beforeEach(preventNavigationRouterHookFactory(pinia))
  if(isDebounceEnabled) {
    router.beforeEach(startDebounceTimerRouterHookFactory(pinia, 500))
  }
  router.beforeEach(startPageLoadingRouterHookFactory(pinia))
  router.beforeEach(initializeAppRouterHookFactory())
  router.beforeEach(ensureAuthRouterHookFactory(pinia))
  router.beforeEach(ensureWorkspaceRouterHookFactory(pinia))
  if(isDebounceEnabled) {
    router.beforeEach(awaitDebounceTimerRouterHookFactory(pinia))
  }
  router.beforeEach(beforeDatabaseViewPageRouterHookFactory(pinia))
  router.beforeEach((to, from, next) => {
    // TODO: Remove this after fixing the issue in vue-router
    Object.keys(from.params).forEach(key => {
      if (to.params[key] === undefined) {
        to.params[key] = ' '
      }
    })
    next()
  })
  router.afterEach(setTransitionNameRouterHookFactory())
  router.afterEach(markFirstRouteAsInitializedRouterHookFactory(pinia))
  router.afterEach(finishPageLoadingRouterHookFactory(pinia))

  if(ENV.SentryDsn) {
    Sentry.init({
      release: `${ENV.ProjectName}@${ENV.Version}`,
      environment: ENV.EnvironmentName,
      app: vueApp,
      dsn: ENV.SentryDsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
}
